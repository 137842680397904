import React, { useEffect, useState } from "react";
import Ads from "./../components/comon/Ads";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import LessonItemRelated from "../components/lessons-courses/LessonItemRelated";
import { useAppContext } from "../contextApi/AppContext";
import ScheduleMeetingModal from "../components/modal/ScheduleMeetingModal";
import { Col, Spinner, Card, Button, ListGroup, Table } from "react-bootstrap";
import { FaCalendarAlt, FaExternalLinkAlt } from "react-icons/fa";
import { loadStripe } from "@stripe/stripe-js";
import { toast } from "react-toastify";
import axios from "axios";
import { Rating } from "@mui/material";
import { FaTrash } from "react-icons/fa";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import {
  Download,
  Fullscreen,
  Thumbnails,
  Zoom,
} from "yet-another-react-lightbox/plugins";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

const stripePromise = loadStripe(
  "pk_test_51PRWS708i8J7Bqoukp7NUitKQ5wmhOokDfktkMWnRc57EICz4HyYIp8n8soyRDmwACcflugDI91nAGEazLs3QOQ7008GpqmAmj"
);

const LessonCoursesDetail = ({ initialDetail }) => {
  const { id } = useParams();
  const {
    getLessonDetail,
    lessonDetail,
    userData,
    getReletedProduct,
    reletedList,
    setlessonDetail,
    createFreePayment,
    token,
    baseURL,
    wishListRemove,
    wishListAdd,
    productrating,
    getreviews,
    reviews,
    reviewDelete,
    isLogIn,
    globalCurrency,
    currency_symbol,
  } = useAppContext();

  const [isWish, setIsWish] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const handleShow = () => setModalShow(true);
  const handleClose = () => setModalShow(false);
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");
  const [images, setImages] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [openIndex, setOpenIndex] = useState(null);
  const location1 = useLocation();

  const redirect = (path) => {
    if (isLogIn) {
      navigate(path);
    } else {
      toast("please Login first");
      setTimeout(() => {
        navigate(`/login?redirect=${location1.pathname}`);
      }, 1000);
    }
  };

  // Function to handle opening the lightbox with the selected image
  const openLightbox = (index) => setOpenIndex(index);

  // Function to handle closing the lightbox
  const closeLightbox = () => setOpenIndex(null);
  const navigate = useNavigate();

  const addWhishlist = (id) => {
    if (isWish) {
      wishListRemove(id);
      setIsWish(false);
    } else {
      wishListAdd(id);
      setIsWish(true);
    }
  };
  const filteredPackages = userData?.membership_details?.find(
    (item) => item.type === "Zoom"
  );

  // const filteredPackages = userData?.membership_details?.find(
  //   (item) => item.type === "Zoom"
  // );

  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };

  const handleReviewChange = (event) => {
    setReview(event.target.value);
  };

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    setImages(files);
    setPreviewImages(files.map((file) => URL.createObjectURL(file)));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (rating === 0) {
      toast.info("Please add a rating first.");
      return;
    }
    setIsSubmitted(true);
    const formData = new FormData();
    formData.append("productId", product_id);
    formData.append("rating", rating);
    formData.append("review", review);
    images.forEach((image, index) => {
      formData.append(`attachments[${index}]`, image);
    });
    await productrating(formData);

    setRating("");
    setReview("");
    setImages([]);
    setIsSubmitted(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const dataToSend = {
        id : id,
        currency: globalCurrency
      }
      await getLessonDetail(dataToSend);
    };
    if (!initialDetail && id) {
      fetchData();
    }
    if (!!initialDetail && !!initialDetail.data && !!initialDetail.data._id) {
      setlessonDetail(initialDetail);
    }
    // eslint-disable-next-line
  }, [id, initialDetail,  globalCurrency,currency_symbol,]);
  const {
    description,
    title,
    attribute_values,
    category_name,
    category_id,
    category_slug,
    product_id,
    sub_category_id,
    userId,
    product_plans,
    purchase_mode,
    product_price,
    converted_currency_price,
    user_subscription,
    image,
    isWishlist,
    what_you_will_learn,
    ratings,
    totalReviews,
    upcoming_meetings,
  } = lessonDetail?.data || {};

  const midPoint = Math.ceil(what_you_will_learn?.length / 2);

  // Split the array into two parts
  const firstPart = what_you_will_learn?.slice(0, midPoint);
  const secondPart = what_you_will_learn?.slice(midPoint);

  useEffect(() => {
    if (product_id) {
      getreviews(product_id);
    }
    // eslint-disable-next-line
  }, [product_id]);

  useEffect(() => {
    setIsWish(isWishlist || false);
  }, [isWishlist]);

  // console.log(attribute_values, 'attribute_values',)
  const createSubsPayment = async (plan_id) => {
    try {
      setLoader(true);
      if (!token) {
        toast.info("Login first");
        return;
      }
      const { data } = await axios.post(
        `${baseURL}/api/subscription/session/create`,
        { plan_id, id },
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      if (data.status === 200) {
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          sessionId: data.data,
        });

        if (error) {
          console.error("Error redirecting to checkout", error);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "server error");
    } finally {
      setLoader(false);
    }
  };

  const createPaidPayment = async (product_id) => {
    try {
      setLoader(true);
      if (!token) {
        toast.info("Login first");
        return;
      }
      const { data } = await axios.post(
        `${baseURL}/api/payment/session/create `,
        // { product_id, redirect: `http://localhost:3000`, id },
        { product_id, id },
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      if (data.status === 200) {
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          sessionId: data.data,
        });
        if (error) {
          console.error("Error redirecting to checkout", error);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "server error");
    } finally {
      setLoader(false);
    }
  };

  const handleSubsPayment = () => {
    createSubsPayment(product_plans?._id);
  };

  const handlePaidPayment = () => {
    createPaidPayment(product_id);
    // navigate(`/learn/welcome?courseId=${id}`);
  };

  const handleFreePayment = () => {
    createFreePayment(product_id);
    navigate(`/learn/welcome?courseId=${id}`);
  };

  // function to get data of lessonDetail, videos, Classes, hours
  const getAttributeValue = (attributeName) => {
    if (!Array.isArray(attribute_values)) return null;
    const attribute = attribute_values?.find(
      (item) => item.attribute === attributeName
    );
    if (attribute && attribute.value) {
      const number = parseInt(attribute?.value);
      if (!isNaN(number)) {
        return number;
      }
    }
    return null;
  };

  const lessons = getAttributeValue("No. of Lessons");
  const Classes = getAttributeValue("No. of Classes");
  const hours = getAttributeValue("No. of Hours");

  useEffect(() => {
    if (product_id && category_id) {
      getReletedProduct(category_id, sub_category_id, product_id);
    }
    // eslint-disable-next-line
  }, [product_id, category_id, sub_category_id]);

  const sharePost = async () => {
    const shareData = {
      title: "Dzital",
      url: window.location.href,
    };
    try {
      await navigator.share(shareData);
    } catch (err) {
      console.log("Error sharing the post:", err.message);
    }
  };

  const handleDelete = (id) => {
    const deleteConfirm = window.confirm(
      "Are you sure you want to delete this Review?"
    );
    if (deleteConfirm) {
      const dataToSend = {
        product_id: product_id,
        reviewId: id,
      };
      reviewDelete(dataToSend);
    }
  };

  return (
    <>
      <section className="pageBanner">
        <article className="container">
          <aside className="row">
            <div className="col-md-12">
              <ul className="navList">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to={`/${category_slug}?category_id=${category_id}`}>
                    {category_name}
                  </Link>
                </li>
                <li className="active">Detail Page Of {title}</li>
              </ul>
            </div>
          </aside>
        </article>
      </section>
      {/* <HeroBanner /> */}
      <section className="midBody bgtheme">
        <article className="container-fluid">
          <article className="row">
            {/* ad box start */}
            <Ads />
            {/* ad box end */}
            {/* mid body part start */}
            {lessonDetail.isLoad && (
              <div className="col-md-10 d-flex justify-content-center ">
                <div className="spinner-grow text-info my-4" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}

            {!lessonDetail.isLoad && (
              <div className="col-md-10">
                <div className="row mb-3">
                  <Col md={4} className="order-md-1 ">
                    <div
                      className="sticky-top"
                      style={{ top: "130px", zIndex: 1 }}
                    >
                      <Card className="mt-0">
                        <Card.Body>
                          <div
                            className="position-relative"
                            style={{ overflow: "hidden" }}
                          >
                            <img
                              src={image}
                              className="card-img-top"
                              alt={""}
                              style={{
                                transition: "transform 0.2s",
                                width: "100%",
                                height: "200px",
                              }}
                              onMouseEnter={(e) =>
                                (e.currentTarget.style.transform = "scale(1.1)")
                              }
                              onMouseLeave={(e) =>
                                (e.currentTarget.style.transform = "scale(1)")
                              }
                            />
                            <div
                              className="position-absolute d-flex align-items-center justify-content-center"
                              style={{
                                top: 10,
                                right: 10,
                                background: "#fff",
                                borderRadius: "50%",
                                width: 30,
                                height: 30,
                              }}
                            >
                              <i
                                className="fa fa-share-alt text20 Cursor"
                                onClick={sharePost}
                              />
                            </div>
                          </div>
                          {purchase_mode === "Subscription" && (
                            <>
                              <h6 className="card-title mt-3">
                                Plan Name : {product_plans?.plan_name}
                              </h6>
                              <p className="card-text d-flex align-items-center gap-1 mb-1 ">
                                <FaCalendarAlt />{" "}
                                <span>
                                  {product_plans?.plan_interval_count}{" "}
                                  {product_plans?.plan_interval}
                                </span>
                              </p>
                            </>
                          )}
                          {purchase_mode !== "Free" && purchase_mode ? (
                            <Card.Text
                              className="m-0 fs-5 mb-2"
                              style={{ fontWeight: 700 }}
                            >
                              {currency_symbol ? currency_symbol : "$"} {''}
                              {purchase_mode === "Subscription"
                                ? `${product_plans?.plan_price} / ${product_plans?.plan_interval}`
                                : converted_currency_price}
                            </Card.Text>
                          ) : (
                            <Card.Text className="text-muted fs-5 my-2">
                              Free Subscription
                            </Card.Text>
                          )}
                          {user_subscription?.user_id === userData._id &&
                          user_subscription?.status === "Active" &&
                          user_subscription?.isEnded === false ? (
                            <Button
                              variant="success"
                              style={{ width: "100%" }}
                              disabled
                              block
                            >
                              Already Subscribed{" "}
                            </Button>
                          ) : userData._id === userId ? (
                            <Link
                              to={`/course_instructor?_id=${product_id}`}
                              className="btn btn-success text-white w-100"
                            >
                              See Details
                            </Link>
                          ) : purchase_mode === "Subscription" ? (
                            <Button
                              className="text-white w-100"
                              variant="success"
                              onClick={handleSubsPayment}
                            >
                              {loader ? (
                                <Spinner animation="border" variant="info" />
                              ) : (
                                "Subscribe Now"
                              )}
                            </Button>
                          ) : purchase_mode === "Paid" ? (
                            <Button
                              className="text-white w-100"
                              variant="success"
                              onClick={handlePaidPayment}
                            >
                              {loader ? (
                                <Spinner animation="border" variant="info" />
                              ) : (
                                "Buy Now"
                              )}
                            </Button>
                          ) : (
                            <Button
                              className="text-white w-100"
                              variant="success"
                              onClick={handleFreePayment}
                            >
                              Enroll for free
                            </Button>
                          )}
                          {/* {userId === userData._id ? (
                            <Button
                            className="text-white w-100 mt20"
                            variant="success"
                            // onClick={handleFreePayment}
                          >
                            Create Zoom Class 
                          </Button>
                          ): (
                          <Card.Text className="text-muted text-center">
                            Cancel anytime
                          </Card.Text>
                          )} */}

                          {isLogIn && (
                            <>
                              {userData._id !== userId ? (
                                <div>
                                  <h3 className="mt-4">Upcoming Classes</h3>
                                  {Array.isArray(upcoming_meetings) &&
                                  upcoming_meetings.length > 0 ? (
                                    <Table
                                      striped
                                      bordered
                                      hover
                                      className="mt-3"
                                    >
                                      <thead>
                                        <tr>
                                          <th>Date</th>
                                          <th>Time</th>
                                          <th>Subject</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {Array.isArray(upcoming_meetings) &&
                                          upcoming_meetings.map(
                                            (classItem, index) => {
                                              const dateObj = new Date(
                                                classItem?.meetingAt
                                              );
                                              const year =
                                                dateObj.getFullYear();
                                              const month = String(
                                                dateObj.getMonth() + 1
                                              ).padStart(2, "0"); // Months are zero-indexed
                                              const day = String(
                                                dateObj.getDate()
                                              ).padStart(2, "0");
                                              const formattedDate = `${year}-${month}-${day}`;
                                              const hours = String(
                                                dateObj.getHours()
                                              ).padStart(2, "0");
                                              const minutes = String(
                                                dateObj.getMinutes()
                                              ).padStart(2, "0");
                                              return (
                                                <tr key={index}>
                                                  <td>{formattedDate}</td>
                                                  <td>{`${hours}:${minutes}`}</td>
                                                  <td>
                                                    {classItem.meeting_agenda}
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )}
                                      </tbody>
                                    </Table>
                                  ) : (
                                    <p className="">No classes found</p>
                                  )}
                                </div>
                              ) : (
                                filteredPackages && (
                                  <div className="App">
                                    <h3 className="mt-4">Scheduled Classes</h3>
                                    <Table
                                      striped
                                      bordered
                                      hover
                                      className="mt-3"
                                    >
                                      <thead>
                                        <tr>
                                          <th>Date</th>
                                          <th>Time</th>
                                          <th>Topic</th>
                                          <th>Action</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {Array.isArray(upcoming_meetings) &&
                                          upcoming_meetings.map(
                                            (classItem, index) => {
                                              const dateObj = new Date(
                                                classItem?.meetingAt
                                              );
                                              const year =
                                                dateObj.getFullYear();
                                              const month = String(
                                                dateObj.getMonth() + 1
                                              ).padStart(2, "0");
                                              const day = String(
                                                dateObj.getDate()
                                              ).padStart(2, "0");
                                              const formattedDate = `${year}-${month}-${day}`;
                                              const hours = String(
                                                dateObj.getHours()
                                              ).padStart(2, "0");
                                              const minutes = String(
                                                dateObj.getMinutes()
                                              ).padStart(2, "0");
                                              return (
                                                <tr key={index}>
                                                  <td>{formattedDate}</td>
                                                  <td>{`${hours}:${minutes}`}</td>
                                                  <td>
                                                    {classItem.meeting_agenda}
                                                  </td>
                                                  <td>
                                                    <Button
                                                      onClick={() => {
                                                        window.open(
                                                          classItem.meeting_start_url,
                                                          "_blank"
                                                        );
                                                      }}
                                                      variant="success"
                                                    >
                                                      Start Class
                                                    </Button>
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )}
                                      </tbody>
                                    </Table>
                                    <Button
                                      className="mt-4"
                                      style={{ color: "white" }}
                                      variant="primary"
                                      onClick={handleShow}
                                    >
                                      Schedule New Meeting
                                    </Button>
                                    <ScheduleMeetingModal
                                      show={modalShow}
                                      handleClose={handleClose}
                                      product_id={product_id}
                                    />
                                  </div>
                                )
                              )}
                            </>
                          )}
                          <hr />
                          <div className="card m-0">
                            <div className="card-header d-flex align-items-center justify-content-between">
                              <h5 className="mb-0">Educator Information</h5>
                              <Link
                                to={`/seller-info/${lessonDetail?.data.seller_details?._id}`}
                              >
                                <img
                                  src={
                                    lessonDetail?.data?.seller_details?.image
                                  }
                                  alt="Seller"
                                  className="rounded-circle"
                                  style={{ width: "40px", height: "40px" }}
                                />
                                <FaExternalLinkAlt
                                  className=""
                                  style={{ marginLeft: 10 }}
                                />
                              </Link>
                            </div>
                            <div className="card-body">
                              <p>
                                <strong>Educator Name:</strong>{" "}
                                <span>
                                  {lessonDetail?.data?.seller_details?.name}
                                </span>
                              </p>
                              <p>
                                <strong>Mobile:</strong>{" "}
                                <span>
                                  {lessonDetail?.data.seller_details?.mobile}
                                </span>
                              </p>
                              <p>
                                <strong>Email:</strong>{" "}
                                <span>
                                  {lessonDetail?.data.seller_details?.email}
                                </span>
                              </p>
                              <p>
                                <strong>Address:</strong>{" "}
                                <span>
                                  {lessonDetail?.data.seller_details?.address}
                                </span>
                              </p>
                            </div>
                          </div>
                          <hr />
                          <Button
                            variant="primary"
                            className="text-light"
                            disabled={userId === userData._id}
                            style={{ width: "100%" }}
                            block
                            onClick={() => addWhishlist(product_id)}
                          >
                            {isWish ? "Added To Wishlist" : "Add To Wishlist"}
                            <i
                              className={
                                isWish
                                  ? "fa fa-heart text-danger fs-6 mx-2"
                                  : "fa fa-heart-o fs-6 mx-2"
                              }
                            />
                          </Button>
                        </Card.Body>
                      </Card>
                    </div>
                  </Col>
                  <Col md={8} className="order-md-0 ">
                    <h2 className="" style={{ fontWeight: 600 }}>
                      {title}
                    </h2>
                    {description &&
                      (/<\/?[a-z][\s\S]*>/i.test(description) ? (
                        <div
                          dangerouslySetInnerHTML={{ __html: description }}
                        />
                      ) : (
                        <p>
                          <strong style={{ color: "#1564db" }}>
                            {/* Job Description - */}
                          </strong>{" "}
                          {description}
                        </p>
                      ))}
                    <div className="d-flex align-items-center gap-2">
                      <span
                        className="badge text-light"
                        style={{ background: "#ffa534" }}
                      >
                        Bestseller
                      </span>
                      <Rating defaultValue={ratings} precision={1} readOnly />
                      <span className="" style={{ color: "#000" }}>
                        {" "}
                        {ratings}
                      </span>
                      <span className="text-muted">
                        ({totalReviews} ratings)
                      </span>
                    </div>
                    <p className="mt-3 text-muted">
                      Created by{" "}
                      <Link
                        to={`/seller-info/${lessonDetail?.data.seller_details?._id}`}
                        style={{ fontWeight: 600 }}
                      >
                        {lessonDetail?.data?.seller_details?.name}
                      </Link>
                      , Educator
                    </p>
                    <p className="text-muted">
                      Posted At{" "}
                      {new Date(
                        lessonDetail?.data?.createdAt
                      ).toLocaleDateString()}{" "}
                      • English
                    </p>
                    {/* <Button variant="primary" size="lg">Preview this course</Button> */}

                    <hr className="my-4" />

                    {what_you_will_learn?.length > 0 && (
                      <>
                        <h3>What you'll learn</h3>
                        <div
                          className="d-flex mt-2 flex-wrap"
                          style={{ gap: 16 }}
                        >
                          <div
                            style={{
                              background: "#fff",
                              borderRadius: 12,
                              flex: 1,
                              minWidth: 250,
                            }}
                          >
                            <ListGroup
                              variant="flush"
                              style={{ borderRadius: 12 }}
                            >
                              {firstPart?.map((item, index) => (
                                <ListGroup.Item key={index}>
                                  ✔️ {item}
                                </ListGroup.Item>
                              ))}
                            </ListGroup>
                          </div>
                          <div
                            style={{
                              background: "#fff",
                              borderRadius: 12,
                              flex: 1,
                              minWidth: 250,
                            }}
                          >
                            <ListGroup
                              variant="flush"
                              style={{ borderRadius: 12 }}
                            >
                              {secondPart?.map((item, index) => (
                                <ListGroup.Item key={index + midPoint}>
                                  ✔️ {item}
                                </ListGroup.Item>
                              ))}
                            </ListGroup>
                          </div>
                        </div>
                        <hr className="my-4" />
                      </>
                    )}

                    <h3>This course includes:</h3>
                    <ListGroup
                      variant="flush"
                      className="my-2"
                      style={{ borderRadius: 12 }}
                    >
                      <ListGroup.Item>
                        📺 {hours} hours on-demand video
                      </ListGroup.Item>
                      <ListGroup.Item>⌨️ {Classes} classes</ListGroup.Item>
                      <ListGroup.Item>📄 {lessons} lessons</ListGroup.Item>
                      {/* <ListGroup.Item>
                        📥 {videos} downloadable resources
                      </ListGroup.Item> */}
                      {/* <ListGroup.Item>
                        📱 Access on mobile and TV
                      </ListGroup.Item> */}
                      <ListGroup.Item>🔁 Full lifetime access</ListGroup.Item>
                      {/* <ListGroup.Item>
                        🏆 Certificate of completion
                      </ListGroup.Item> */}
                    </ListGroup>

                    {Array.isArray(reviews.data) ? (
                      <div className="mt-5">
                        <div
                          className="card"
                          style={{ height: "310px", overflowY: "auto" }}
                        >
                          <div className="card-header">
                            <h5 className="mb-0">Course Reviews:</h5>
                          </div>
                          {Array.isArray(reviews.data) &&
                          reviews.data?.length > 0 ? (
                            reviews.data.map((review) => (
                              <div
                                key={review._id}
                                style={{ position: "relative" }}
                              >
                                <div className="card-body align-item-center">
                                  {userData._id === review.userId && (
                                    <FaTrash
                                      className="text-danger"
                                      style={{
                                        cursor: "pointer",
                                        position: "absolute",
                                        top: "30px",
                                        right: "10px",
                                      }}
                                      onClick={() => handleDelete(review._id)}
                                    />
                                  )}
                                  <div className="d-flex align-items-center mb-3">
                                    <img
                                      src={review.user_img}
                                      alt="User"
                                      className="rounded-circle mr-3"
                                      style={{ width: "50px", height: "50px" }}
                                    />
                                    <div
                                      className="user-profileinfo d-flex"
                                      style={{ marginLeft: "10px" }}
                                    >
                                      <h6 style={{ marginRight: "10px" }}>
                                        {review.user_name}
                                      </h6>
                                      <div className="rating">
                                        {[...Array(5)].map((star, index) => (
                                          <i
                                            key={index}
                                            className={`fas fa-star ${
                                              index < review.rating
                                                ? "text-warning"
                                                : "text-secondary"
                                            }`}
                                          ></i>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                  <p className="mt-3">
                                    {review.review}.. <strong>Review on</strong>{" "}
                                    :{" "}
                                    <small className="text-muted">
                                      {new Date(
                                        review.createdAt
                                      ).toLocaleDateString()}
                                    </small>{" "}
                                  </p>
                                  <div className="mt-3">
                                    {review.attachments.map((src, index) => (
                                      <img
                                        key={index}
                                        src={src}
                                        alt="Review attachment"
                                        className="img-thumbnail mr-2 mb-2"
                                        onClick={() => openLightbox(index)}
                                        style={{
                                          width: "100px",
                                          height: "100px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    ))}
                                  </div>
                                </div>
                                <hr />
                              </div>
                            ))
                          ) : (
                            <div className="card-body text-center">
                              <p>No reviews found</p>
                            </div>
                          )}
                        </div>

                        {openIndex !== null && (
                          <Lightbox
                            plugins={[Download, Fullscreen, Zoom, Thumbnails]}
                            open={true}
                            close={closeLightbox}
                            slides={reviews.data.flatMap((review) =>
                              review.attachments.map((src) => ({ src }))
                            )}
                            index={openIndex}
                          />
                        )}
                      </div>
                    ) : (
                      <div> No Reviws Found</div>
                    )}

                    {userData._id !== userId && (
                      <div className="card">
                        <div className="card-header">
                          <h4>Rate this course:</h4>
                        </div>
                        <div className="card-body">
                          <div className="rating mt-4">
                            {[...Array(5)].map((star, index) => (
                              <button
                                key={index}
                                type="button"
                                className={`btn btn-link ${
                                  index < rating
                                    ? "text-warning"
                                    : "text-secondary"
                                }`}
                                onClick={() => handleRatingChange(index + 1)}
                              >
                                <i className="fas fa-star"></i>
                              </button>
                            ))}
                          </div>
                          <form onSubmit={handleSubmit} className="mt-4">
                            <div className="form-group">
                              <label htmlFor="review">Review:</label>
                              <textarea
                                id="review"
                                disabled={isSubmitted}
                                className="form-control"
                                value={review}
                                onChange={handleReviewChange}
                              ></textarea>
                            </div>
                            <div className="form-group mt-3">
                              <label htmlFor="images">Upload Images:</label>
                              <input
                                type="file"
                                id="images"
                                disabled={isSubmitted}
                                className="form-control"
                                multiple
                                onChange={handleImageChange}
                              />
                              <div className="preview-images mt-3">
                                {previewImages?.map((src, index) => (
                                  <img
                                    key={index}
                                    src={src}
                                    alt="Preview"
                                    className="img-thumbnail mr-2"
                                    style={{ width: "100px", height: "100px" }}
                                  />
                                ))}
                              </div>
                            </div>
                            {!isLogIn ? (
                              <button
                                className="btn btn-primary mt-2 text-white"
                                onClick={() => redirect("/message")}
                                disabled={isSubmitted}
                              >
                                LogIn First To Submit Review
                              </button>
                            ) : (
                              <button
                                type="submit"
                                className="btn btn-primary mt-2 text-white"
                              >
                                Submit Review
                              </button>
                            )}
                          </form>
                        </div>
                      </div>
                    )}
                  </Col>
                  <div className="col-md-12 order-md-2">
                    <h5 className="mt-3">Related Courses</h5>
                    <hr className="mt0" />
                    <div className="row">
                      {Array.isArray(reletedList) &&
                        reletedList.map((item) => (
                          <LessonItemRelated item={item} key={item._id} />
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* Old */}

            {/* mid body part end */}
            {/* ad box start */}
            <Ads />
            {/* ad box end */}
          </article>
        </article>
      </section>
    </>
  );
};

export default LessonCoursesDetail;