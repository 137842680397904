import React, { useEffect, useState } from 'react'
import { useAppContext } from '../contextApi/AppContext'
import { useParams } from 'react-router-dom'
import RealstateDetail from './RealstateDetail'
import JobDetail from './JobDetail'
import FreelancerDetail from './FreelancerDetail'
import LessonCoursesDetail from './LessonCoursesDetail'
import FashionBeautyDetails from '../components/fashion-beauty/FashionBeautyDetails'
import ElectronicsDetail from './ElectronicsDetail'
import ProductDetail from './ProductDetail'

const AllProductDetails = () => {
    const { getAllProductDetails, getAllUnAuthProductDetails, allPDetails, isLogIn } = useAppContext()
    const params = useParams()
   console.log(allPDetails)

    useEffect(() => {
        const fetchData = async () => {
            if (isLogIn) await getAllProductDetails(params.id)
            else await getAllUnAuthProductDetails(params.id)
        };
        if (params.id) {
            fetchData();
        }
        // eslint-disable-next-line
    }, [params.id])

    if (!allPDetails || !allPDetails.data) {
        return null
    }

    return (
        <div>
            {allPDetails.data?.category_slug === "real_estate" && <RealstateDetail initialDetail={allPDetails} />}
            {allPDetails.data?.category_slug === "jobs" && <JobDetail initialDetail={allPDetails} />}
            {allPDetails.data?.category_slug === "freelancer" && <FreelancerDetail initialDetail={allPDetails} />}
            {allPDetails.data?.category_slug === "lesson_courses" && <LessonCoursesDetail initialDetail={allPDetails} />}
            {allPDetails.data?.category_slug === "fashion_beauty" && <FashionBeautyDetails initialDetail={allPDetails} />}
            {allPDetails.data?.category_slug === "electronics" && <ElectronicsDetail initialDetail={allPDetails} />}
            {allPDetails.data?.category_slug === "goods_of_all_kinds" && <ProductDetail initialDetail={allPDetails} />}
        </div>
    )
}

export default AllProductDetails