import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAppContext } from "../../contextApi/AppContext";

const RealstateProduct = ({ item, currency_symbol }) => {
  const { wishListAdd, wishListRemove, userData } = useAppContext();
  const [color, setColor] = useState(item.isWishlist);

  const handleWhishlist = (id) => {
    if (color) {
      wishListRemove(id);
    } else {
      wishListAdd(id);
    }
    setColor(!color);
  };

  const numberOfBedrooms =
    item?.attribute_values?.find(
      (attr) => attr.attribute === "Number of Bedrooms"
    )?.value || null;

  const funrnished =
    item?.attribute_values?.find((attr) => attr.attribute === "Furnished")
      ?.value || null;

  const property_type = item.property_type;

  // const formatPrice = (price) => {
  //   if (price >= 10000000) {
  //     // If price is 1 crore or more, show in crore
  //     return `${(price / 10000000).toFixed(2)} Cr`;
  //   } else if (price >= 100000) {
  //     // If price is 1 lakh or more, show in lakh
  //     return `${(price / 100000).toFixed(2)} L`;
  //   } else if (price >= 1000) {
  //     // If price is 1 thousand or more, show in thousand
  //     return `${(price / 1000).toFixed(2)} K`;
  //   } else {
  //     // Otherwise, show the price as is
  //     return `${price}`;
  //   }
  // };

  return (
    <>
      <div className="card mb-3 realestateItem">
        <div className="row g-0" key={item._id}>
          <div className="col-md-4">
            <Link to={`/realstate-detail/${item._id}`}>
              <img
                src={item?.image[0]}
                className="img-fluid rounded-start allImg"
                alt="..."
                width={400}
                style={{ height: "240px" }}
              />
            </Link>
          </div>
          <div className="col-md-8">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-start">
                <h5 className="card-title mb-0 text-truncate">
                  <Link
                    className="text-primary "
                    to={`/realstate-detail/${item._id}`}
                  >
                    {item.title}
                  </Link>
                </h5>
                {userData._id !== item.userId && (
                  <div
                    className="iconWish position-absolute top-0 end-0 p-2"
                    onClick={() => handleWhishlist(item.product_id)}
                    style={{ cursor: "pointer" }}
                  >
                    <i
                      className={`fa ${
                        color ? "fa-heart text-danger" : "fa-heart-o"
                      }`}
                    />
                  </div>
                )}
              </div>
              <small>
                <b className="text-muted">Posted by : {item?.seller_name}</b>
              </small>
              <p className="fs-6 mt-2 mb-2 lh-1">
                {" "}
                {item.description
                  ? item.description.length > 30
                    ? `${item.description.slice(0, 100)}...`
                    : item.description
                  : ""}
              </p>
              {(numberOfBedrooms || funrnished || property_type) && (
                <div className="row">
                  <div className="col border-end">
                    {/* <h5>Property Details</h5> */}
                    <i className="fas fa-home text-muted"></i>{" "}
                    {numberOfBedrooms ? `${numberOfBedrooms} BHK` : "N/A"}
                  </div>
                  <div className="col border-end">
                    <i className="fas fa-holly-berry text-muted"></i>{" "}
                    {funrnished ? `${funrnished} ` : "N/A"}
                  </div>
                  <div className="col">
                    <i className="fa-solid fa-building-columns"></i>{" "}
                    {property_type ? `${property_type}` : "N/A"}
                  </div>
                </div>
              )}
              <p className="mt-2 mb-2 text-truncate">
                <i className="fa-solid fa-location-dot"></i> {item?.address}{" "}
              </p>
              <div className="row mt-2">
                <div className="col">
                  <b>Property Price:</b>&nbsp;
                  <strong className="text-primary fs-5">
                    {currency_symbol ? currency_symbol : "$"}{" "}
                    {item.converted_currency_price}
                  </strong>
                </div>
                <div className="col" align="right">
                  <Link
                    className="btn btnTheme text-white"
                    style={{ background: "var(--theme-color)" }}
                    to={`/realstate-detail/${item._id}`}
                  >
                    View Property <i className="far fa-paper-plane"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RealstateProduct;
