import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import Ads from "../components/comon/Ads";
import { useAppContext } from "../contextApi/AppContext";
import user from "../../src/assets/assets/images/user.jpg";

const ApplicantList = () => {
  const { applicantList, getApplicantList } = useAppContext();
  const [page, setPage] = useState(1);
  const limit = 20;

  useEffect(() => {
    const dataToSend = {
      page,
      limit,
    };
    applicantList(dataToSend);
  }, [page]);

  const totalCount = getApplicantList.total_count || 0;
  const totalPages = Math.ceil(totalCount / limit);

  const handlePrevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePageClick = (pageNumber) => {
    setPage(pageNumber);
  };

  // console.log(getApplicantList, "getApplicantList");

  return (
    <>
      {/* page banner start here */}
      <section className="pageBanner">
        <article className="container">
          <aside className="row">
            <div className="col-md-6">
              <h4>List of Job Seekers Page</h4>
            </div>
            <div className="col-md-6">
              <ul className="navList">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active">List of Job Seekers</li>
              </ul>
            </div>
          </aside>
        </article>
      </section>
      {/* page banner end here */}

      {/* page body start */}
      <section className="midBody bgtheme">
        <article className="container-fluid">
          <article className="row">
            {/* ad box start */}
            <Ads />
            {/* ad box end */}

            <div className="col-md-10">
              {/************** new section work start here **************/}
              <div className="row">
                {Array.isArray(getApplicantList.data) &&
                  getApplicantList.data.map((applicant, index) => (
                    <div className="col-md-6">
                      <Link to={`/seller-info/${applicant?._id}`}
                        className="jobeItem"
                      >
                        <div className="card mb20">
                          <div className="card-body" style={{ height: "auto" }}>
                            <div className="row">
                              <div className="col-md-2 col-sm-12 text-center">
                                <img
                                  src={applicant?.job_applicant_image || user}
                                  width={72}
                                  height={72}
                                  alt="user"
                                />
                              </div>
                              <div className="col-md-9 col-sm-12">
                                <h5 className="mt-1 mb-0">
                                  {applicant?.job_applicant}{" "}
                                </h5>
                                <p className="mt-1 fs-6">
                                  <b>
                                    <i className="fa fa-map-marker text-bg-success p-1 round1"></i>{" "}
                                    Address:
                                  </b>{" "}
                                  {applicant.job_applicant_address}
                                </p>
                              </div>
                            </div>
                            <ul className="list-inline divider mt-3">
                              {Array.isArray(applicant.job_details) &&
                                applicant.job_details.map((jobDetail, idx) => (
                                  <li key={idx}>
                                    {jobDetail?.sub_category_name}
                                  </li>
                                ))}
                            </ul>
                            <div className="row mt20">
                              <div className="col-6">
                                <span>
                                  <i
                                    className="fa-regular fa-calendar-days"
                                    aria-hidden="true"
                                  />{" "}
                                  Year of experience{" "}
                                  {applicant?.job_applicant_year_of_experience}{" "}
                                  {applicant?.job_applicant_year_of_experience ===
                                  1 
                                    ? "year"
                                    : "years"}
                                </span>
                              </div>
                              <div className="col-6" align="right">
                                <button
                                  type="button"
                                  className="btn btn-primary btn-sm text-white btn-block"
                                >
                                  <i className="fa-regular fa-comment"></i> Chat
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
              </div>
              {/************** new section work end here **************/}

              <Pagination className="paginationBx">
                <Pagination.Prev
                  onClick={handlePrevPage}
                  disabled={page === 1}
                />
                {Array.from({ length: totalPages }, (_, index) => (
                  <Pagination.Item
                    key={index + 1}
                    active={index + 1 === page}
                    onClick={() => handlePageClick(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={handleNextPage}
                  disabled={page === totalPages}
                />
              </Pagination>
            </div>

            {/* ad box start */}
            <Ads />
            {/* ad box end */}
          </article>
        </article>
      </section>
      {/* page body end */}
    </>
  );
};

export default ApplicantList;