import React, { useEffect, useState } from "react";
import Ads from "../components/comon/Ads";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { Link, useParams } from "react-router-dom";
import { useAppContext } from "../contextApi/AppContext";
import CallAction from "../components/comon/CallAction";
import { ref, set, onValue } from "firebase/database";
import { database } from "../components/firebase/Firebase";
import { toast } from "react-toastify";
import GallerySlider from "../components/sliders/GalerySlider";
import { FaTrash } from "react-icons/fa";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import {
  Download,
  Fullscreen,
  Thumbnails,
  Zoom,
} from "yet-another-react-lightbox/plugins";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

const ProductDetail = ({ initialDetail }) => {
  const { id } = useParams();
  const {
    getGoodsProductDetails,
    goodsItems,
    setGoodsItems,
    wishListAdd,
    wishListRemove,
    BidAdd,
    getReletedProduct,
    reletedList,
    cartListAdd,
    userData,
    productrating,
    getreviews,
    reviews,
    reviewDelete,
    globalCurrency,
    currency_symbol,
  } = useAppContext();
  const [color, setColor] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState("--:--:--");
  const [timeStart, setTimeStart] = useState("--:--:--");
  const [disableBid, setDisableBid] = useState(false);
  const [enteredAmount, setEnteredAmount] = useState("");
  const [quantity, setQuantity] = useState(1);
  // eslint-disable-next-line
  const [suggestion, setSuggestion] = useState("");
  const [realtimeData, setRealtimeData] = useState(null);

  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");
  const [images, setImages] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [open, setOpen] = useState(false);
  const [openIndex, setOpenIndex] = useState(null);
  const [lightboxIndex, setLightboxIndex] = useState(0);

  // Function to handle opening the lightbox with the selected image
  const openLightbox = (index) => setOpenIndex(index);

  const handleRatingChange = (newRating) => {
    setRating(newRating);
    // console.log('New rating set:', rating);
  };

  const handleImageClick = (index) => {
    setLightboxIndex(index);
    setOpen(true);
  };

  const handleReviewChange = (event) => {
    setReview(event.target.value);
  };

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    setImages(files);
    setPreviewImages(files.map((file) => URL.createObjectURL(file)));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("productId", product_id);
    formData.append("rating", rating);
    formData.append("review", review);
    images.forEach((image, index) => {
      formData.append(`attachments[${index}]`, image);
    });

    productrating(formData);
  };

  const {
    description,
    image_1,
    image_2,
    image_3,
    photo,
    product_id,
    title,
    attribute_values,
    bid_now,
    bid_start_price,
    bid_increament_value,
    bid_entry,
    bid_start_date,
    bid_end_date,
    isWishlist,
    category_id,
    category_name,
    category_slug,
    sub_category_id,
    userId,
    seller_details,
    price,
    image, converted_currency_price
  } = goodsItems.data || {};

  useEffect(() => {
    if (isWishlist !== undefined) {
      setColor(isWishlist);
    }
  }, [isWishlist]);

  useEffect(() => {
    if (product_id) {
      getreviews(product_id);
    }
    // eslint-disable-next-line
  }, [product_id]);

  const addWhishlist = (id) => {
    if (!color) {
      wishListAdd(id);
      setColor(true);
    } else {
      wishListRemove(id);
      setColor(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const dataToSend = {
        id: id,
        currency: globalCurrency
      }
      await getGoodsProductDetails(dataToSend);
    };
    if (!initialDetail && id) {
      fetchData();
    }
    if (!!initialDetail && !!initialDetail.data && !!initialDetail.data._id) {
      setGoodsItems(initialDetail);
    }
    // eslint-disable-next-line
  }, [id, initialDetail,globalCurrency,currency_symbol]);

  useEffect(() => {
    const dataRef = ref(database, "bet_entry/" + id);
    const unsubscribe = onValue(dataRef, (snapshot) => {
      setRealtimeData(snapshot.val());
    });

    return () => {
      unsubscribe();
    };
  }, [id]);

  const handleInputChange = (event) => {
    const amount = parseFloat(event.target.value);
    setEnteredAmount(amount);
    updateSuggestion(amount);
  };

  const validAmountVale =
    bid_entry || realtimeData?.bet_entry || 0 + bid_increament_value || 0;

  const updateSuggestion = (amount) => {
    if (amount >= validAmountVale) {
      setSuggestion(
        <button
          className="btn btn-link"
          // onClick={() => {setEnteredAmount(validAmountVale) }}
        >
          {`Amount is valid. (${amount})`}
        </button>
      );
    } else {
      setSuggestion(
        <button
          className="btn btn-link"
          // onClick={() => setEnteredAmount(validAmountVale)}
        >
          {`Bid must be at least ${validAmountVale}`}
        </button>
      );
    }
  };

  useEffect(() => {
    const startDateTime = new Date(bid_start_date).getTime();

    const countdownInterval = setInterval(() => {
      const now = new Date().getTime();

      const timeDifference = startDateTime - now;

      if (timeDifference <= 0) {
        clearInterval(countdownInterval);
        setTimeStart("Bid_Start");
      } else {
        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const minutes = Math.floor(
          (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

        setTimeStart(formattedTime);
      }
    }, 1000);

    // Clean up the interval on unmount
    return () => clearInterval(countdownInterval);
  }, [bid_start_date]);

  useEffect(() => {
    const endTime = new Date(bid_end_date).getTime();

    const countdownInterval = setInterval(() => {
      const now = new Date().getTime();

      const timeDifference = endTime - now;

      if (timeDifference <= 0) {
        clearInterval(countdownInterval);
        setTimeRemaining("Time's up!");
        setDisableBid(true);
      } else {
        setDisableBid(false);
        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const minutes = Math.floor(
          (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

        setTimeRemaining(formattedTime);
      }
    }, 1000);
    return () => clearInterval(countdownInterval);
    // eslint-disable-next-line
  }, [timeRemaining]);

  const img = [photo, image_1, image_2, image_3];
  const getAttributeValue = (attributeName) => {
    if (!Array.isArray(attribute_values)) return null;
    const attribute = attribute_values.find(
      (item) => item.attribute === attributeName
    );
    if (attribute) {
      return attribute.value;
    } else {
      return null;
    }
  };
  // const price = getAttributeValue("Price");
  const brand = getAttributeValue("Brand");

  const Handelbet = async (e) => {
    e.preventDefault();
    try {
      if (enteredAmount >= validAmountVale) {
        if (enteredAmount <= 100 * validAmountVale) {
          const data = await BidAdd(parseFloat(enteredAmount), product_id);
          if (data?.status === 200) {
            await set(ref(database, "bet_entry/" + id), {
              bet_entry: enteredAmount,
            });
            await getGoodsProductDetails(id);
            setEnteredAmount("");
          } else {
            toast.warning(
              `Entered value must be equal to or greater than ${validAmountVale}`
            );
          }
        } else {
          toast.warning(
            `Entered value must be at most 100 times ${100 * validAmountVale}`
          );
        }
      } else {
        toast.warning(
          `Entered value must be equal to or greater than ${validAmountVale}`
        );
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  const increaseQuantity = () => {
    setQuantity(quantity + 1);
  };

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const addToCart = () => {
    if (!price) {
      toast.warning("Price is not defined");
      return;
    }
    cartListAdd(product_id, quantity, +price);
  };

  useEffect(() => {
    if (product_id && category_id) {
      getReletedProduct(category_id, sub_category_id, product_id);
    }
    // eslint-disable-next-line
  }, [product_id, category_id, sub_category_id]);

  const handleDelete = (id) => {
    const deleteConfirm = window.confirm(
      "Are you sure you want to delete this Review?"
    );
    if (deleteConfirm) {
      const dataToSend = {
        product_id: product_id,
        reviewId: id,
      };
      reviewDelete(dataToSend);
    }
  };

  const bidEnded = bid_now && new Date(bid_end_date) < new Date();

  const sharePost = async () => {
    const shareData = {
      title: "Dzital",
      url: window.location.href,
    };

    try {
      await navigator.share(shareData);
    } catch (err) {
      console.log("Error sharing the post:", err.message);
    }
  };

  return (
    <>
      <section className="pageBanner">
        <article className="container">
          <aside className="row">
            <div className="col-md-12">
              <ul className="navList">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to={`/${category_slug}?category_id=${category_id}`}>
                    {category_name}
                  </Link>
                </li>
                <li className="active">Detail Page Of {title}</li>
              </ul>
            </div>
          </aside>
        </article>
      </section>
      <section className="midBody bgtheme">
        <article className="container-fluid">
          <article className="row">
            {/* ad box start */}
            <Ads />
            {/* ad box end */}
            {/* mid box start */}
            {goodsItems.isLoad && (
              <div className="col-md-10 d-flex justify-content-center ">
                <div className="spinner-grow text-info my-4" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}

            {!goodsItems.isLoad && (
              <div className="col-md-10">
                {/* product details part start here */}
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="sticky-top dressDtlsImg">
                          <GallerySlider
                            img={image || img}
                            onImageClick={handleImageClick}
                          />
                        </div>
                        <Lightbox
                          plugins={[Download, Fullscreen, Zoom, Thumbnails]}
                          open={open}
                          close={() => setOpen(false)}
                          slides={img.map((src) => ({ src }))}
                          index={lightboxIndex}
                        />
                      </div>
                      <div className="col-md-8">
                        <div className="row">
                          <div className="col-md-10 col-sm-12">
                            <h5>
                              <span className="badge bg-dark">
                                {brand || ""}
                              </span>
                            </h5>
                          </div>
                          <div className="col-md-2 col-sm-12" align="right">
                            <div className="d-inline-flex">
                              {userData._id === userId ? (
                                ""
                              ) : (
                                <div
                                  className="iconWish"
                                  onClick={() => addWhishlist(product_id)}
                                  style={{ marginRight: "10px" }}
                                >
                                  <i
                                    className={
                                      color === true
                                        ? "fa fa-heart text-danger"
                                        : "fa fa-heart-o"
                                    }
                                  />
                                </div>
                              )}
                              <div className="iconWish">
                                <i
                                  className="fa fa-share-alt text20 Cursor"
                                  onClick={sharePost}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <h4 className="text-muted fst-normal">{title}</h4>
                        <hr />
                        {/* price part start */}
                        <div className="row">
                          <div className="col-md-4">
                            <h5 className="text-danger fw-light">
                              Special price
                            </h5>
                            <h5>
                              <span className="text28 text-success">
                              {currency_symbol ? currency_symbol : "$"}{" "}
                              {converted_currency_price}
                              </span>
                            </h5>
                          </div>
                        </div>

                        {userData._id === userId ? (
                          ""
                        ) : (
                          <>
                            <CallAction peerUser={userId} />
                            {bid_now && (
                              <>
                                {timeStart === "Bid_Start" ? (
                                  <>
                                    <ul>
                                      <li>
                                        <span>
                                          {" "}
                                          <h5>
                                            Bid Start Price :{" "}
                                            {bid_start_price || 0}
                                          </h5>
                                        </span>
                                      </li>
                                      <li>
                                        <span>
                                          {" "}
                                          <h5>
                                            Highest Bid Price :{" "}
                                            {realtimeData?.bet_entry || 0}
                                          </h5>
                                        </span>
                                      </li>
                                      <li>
                                        <span>
                                          <h5>
                                            Bid Ending In(HH:MM:SS) :{" "}
                                            <span className="text text-success">
                                              {" "}
                                              {timeRemaining}
                                            </span>
                                          </h5>
                                        </span>
                                      </li>
                                    </ul>
                                    {!bidEnded && (
                                      <>
                                        <label htmlFor="bid">
                                          <h5>Bid Your Amount</h5>
                                        </label>
                                        <div className="d-flex justify-content-center item-center">
                                          <span className="w-50">
                                            <input
                                              type="number"
                                              id="bid"
                                              style={{ width: "150px" }}
                                              className="pb-2"
                                              value={enteredAmount}
                                              onChange={handleInputChange}
                                            />
                                            <br />
                                            <span>
                                              Suggestion :{" "}
                                              {bid_entry ||
                                                realtimeData?.bet_entry ||
                                                0 + bid_increament_value}
                                            </span>
                                            <br />
                                            <span className="text text-success">
                                              Increment in multipule of :{" "}
                                              {bid_increament_value}
                                            </span>
                                          </span>

                                          <button
                                            disabled={disableBid}
                                            className={`btn ${
                                              disableBid
                                                ? "btn-dark"
                                                : "btn-success"
                                            }`}
                                            style={{
                                              width: "50%",
                                              height: "40px",
                                            }}
                                            onClick={Handelbet}
                                          >
                                            Bid
                                          </button>
                                        </div>
                                      </>
                                    )}
                                    <hr />
                                  </>
                                ) : (
                                  <>
                                    <span>
                                      <h5>
                                        Bid start In (HH:MM:SS):{" "}
                                        <span className="text text-success">
                                          {" "}
                                          {timeStart}
                                        </span>
                                      </h5>
                                    </span>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}

                        {bid_now ? (
                          ""
                        ) : (
                          <div className="col-md-7 d-flex gap-5 align-items-center ">
                            <div className="row">
                              <div className="col-md-5">
                                <div className="input-group">
                                  <button
                                    className="btn btn-outline-secondary"
                                    type="button"
                                    onClick={decreaseQuantity}
                                  >
                                    -
                                  </button>
                                  <input
                                    type="number"
                                    className="form-control"
                                    value={quantity}
                                    readOnly
                                  />
                                  <button
                                    className="btn btn-outline-secondary"
                                    type="button"
                                    onClick={increaseQuantity}
                                  >
                                    +
                                  </button>
                                </div>
                              </div>
                              <div className="col-md-5">
                                <button
                                  className="btn btn-primary text-white"
                                  onClick={addToCart}
                                >
                                  Add To Cart
                                </button>
                              </div>
                            </div>
                          </div>
                        )}

                        {/******** new section work start ********/}
                        <div className="accordion mt-4">
                          {/* item start */}
                          <div className="accordion-item">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button py-2"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOne"
                              >
                                <h5 className="mb-0">Product Details</h5>
                              </button>
                            </h2>
                            <div
                              id="collapseOne"
                              className="accordion-collapse collapse show"
                            >
                              <div className="accordion-body">
                                <div className="row clothDtl">
                                  {Array.isArray(attribute_values) &&
                                    attribute_values.map((item) => (
                                      <div className="col-6" key={item._id}>
                                        <b>{item.attribute} : </b>

                                        {item.value}
                                      </div>
                                    ))}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* item end */}

                          {/* item start */}
                          <div className="accordion-item">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button collapsed py-2"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo"
                              >
                                <h5 className="mb-0">Product Description</h5>
                              </button>
                            </h2>
                            <div
                              id="collapseTwo"
                              className="accordion-collapse collapse"
                            >
                              <div className="accordion-body">
                                {description &&
                                  (/<\/?[a-z][\s\S]*>/i.test(description) ? (
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: description,
                                      }}
                                    />
                                  ) : (
                                    <p>
                                      <strong style={{ color: "#1564db" }}>
                                        Description -
                                      </strong>{" "}
                                      {description}
                                    </p>
                                  ))}
                              </div>
                            </div>
                          </div>
                          {/* item end */}

                          {/* item start */}
                          <div className="accordion-item">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button collapsed py-2"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseThree"
                              >
                                <h5 className="mb-0">Seller Details</h5>
                              </button>
                            </h2>
                            <div
                              id="collapseThree"
                              className="accordion-collapse collapse"
                            >
                              <div className="accordion-body">
                                <div className="row">
                                  <div className="col-md-3">
                                    <Link
                                      to={`/seller-info/${seller_details?._id}`}
                                    >
                                      <img
                                        src={seller_details?.image}
                                        alt="Seller"
                                        className="rounded-circle ms-auto"
                                        style={{ width: "84%", height: "84%" }}
                                      />
                                    </Link>
                                  </div>
                                  <div className="col-md-9">
                                    <ul className="list-unstyled">
                                      <li className="border-bottom pb-1">
                                        <strong>Name:</strong>{" "}
                                        {seller_details?.name}
                                      </li>
                                      <li className="border-bottom pt-1 pb-1">
                                        <strong>Email:</strong>{" "}
                                        {seller_details?.email}
                                      </li>
                                      <li className="border-bottom pt-1 pb-1">
                                        <strong>Mobile:</strong>{" "}
                                        {seller_details?.mobile}
                                      </li>
                                      <li className="pt-1 pb-1">
                                        <strong>Address:</strong>{" "}
                                        {seller_details?.address}
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="d-flex justify-content-end btn-sm">
                                    <Link
                                      to={`/seller-info/${seller_details?._id}`}
                                    >
                                      {" "}
                                      <button className="btn btn-primary text-white">
                                        View Details
                                      </button>{" "}
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* item end */}
                        </div>
                        {/******** new section work end ********/}
                      </div>
                    </div>
                  </div>
                </div>

                {!bidEnded && (
                  <div className="col-md-12 d-flex" style={{ gap: "10px" }}>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="card-header">
                          <h5 className="mb-0">Rate this product:</h5>
                        </div>
                        <div className="card-body">
                          <form onSubmit={handleSubmit} className="mt-4">
                            <div className="row">
                              <div className="col-md-5">
                                <div className="rating mt-4">
                                  {[...Array(5)].map((star, index) => (
                                    <button
                                      key={index}
                                      type="button"
                                      className={`btn btn-link ${
                                        index < rating ? "text-warning" : ""
                                      }`}
                                      onClick={() =>
                                        handleRatingChange(index + 1)
                                      }
                                    >
                                      <i className="fas fa-star"></i>
                                    </button>
                                  ))}
                                </div>
                              </div>
                              <div className="col-md-7">
                                <label htmlFor="images">Upload Images:</label>
                                <input
                                  type="file"
                                  id="images"
                                  className="form-control"
                                  multiple
                                  onChange={handleImageChange}
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label htmlFor="review">Review:</label>
                              <textarea
                                id="review"
                                className="form-control"
                                value={review}
                                onChange={handleReviewChange}
                              ></textarea>
                            </div>
                            <div className="form-group mt-3">
                              <div className="preview-images mt-3">
                                {previewImages.map((src, index) => (
                                  <img
                                    key={index}
                                    src={src}
                                    alt="Preview"
                                    className="img-thumbnail mr-2"
                                    style={{ width: "100px", height: "100px" }}
                                  />
                                ))}
                              </div>
                            </div>
                            <button
                              type="submit"
                              className="btn btn-primary mt-2 text-white"
                            >
                              Submit Review
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div
                        className="card"
                        style={{ height: "310px", overflowY: "auto" }}
                      >
                        <div className="card-header">
                          <h5 className="mb-0">Reviews:</h5>
                        </div>
                        {Array.isArray(reviews.data) &&
                        reviews.data.length > 0 ? (
                          reviews.data.map((review) => (
                            <div
                              key={review._id}
                              style={{ position: "relative" }}
                            >
                              <div className="card-body align-item-center">
                                {userData._id === review.userId && (
                                  <FaTrash
                                    className="text-danger"
                                    style={{
                                      cursor: "pointer",
                                      position: "absolute",
                                      top: "30px",
                                      right: "10px",
                                    }}
                                    onClick={() => handleDelete(review._id)}
                                  />
                                )}
                                <div className="d-flex align-items-center mb-3">
                                  <img
                                    src={review.user_img}
                                    alt="User"
                                    className="rounded-circle mr-3"
                                    style={{ width: "50px", height: "50px" }}
                                  />
                                  <div
                                    className="user-profileinfo d-flex"
                                    style={{ marginLeft: "10px" }}
                                  >
                                    <h6 style={{ marginRight: "10px" }}>
                                      {review.user_name}
                                    </h6>
                                    <div className="rating">
                                      {[...Array(5)].map((star, index) => (
                                        <i
                                          key={index}
                                          className={`fas fa-star ${
                                            index < review.rating
                                              ? "text-warning"
                                              : "text-secondary"
                                          }`}
                                        ></i>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                                <p className="mt-3">
                                  {review.review}.. <strong>Review on</strong> :{" "}
                                  <small className="text-muted">
                                    {new Date(
                                      review.createdAt
                                    ).toLocaleDateString()}
                                  </small>{" "}
                                </p>
                                <div className="mt-3">
                                  {review.attachments.map((src, index) => (
                                    <img
                                      key={index}
                                      src={src}
                                      alt="Review attachment"
                                      className="img-thumbnail mr-2 mb-2"
                                      onClick={() => openLightbox(index)}
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                        cursor: "pointer",
                                      }}
                                    />
                                  ))}
                                </div>
                              </div>
                              <hr />
                            </div>
                          ))
                        ) : (
                          <div className="card-body text-center">
                            <p>No reviews found</p>
                          </div>
                        )}
                      </div>

                      {openIndex !== null && (
                        // <Lightbox
                        //   plugins={[Download, Fullscreen, Zoom, Thumbnails]}
                        //   open={true}
                        //   close={closeLightbox}
                        //   slides={reviews.data.flatMap((review) =>
                        //     review.attachments.map((src) => ({ src }))
                        //   )}
                        //   index={openIndex}
                        // />
                        <Lightbox
                          plugins={[Download, Fullscreen, Zoom, Thumbnails]}
                          open={open}
                          close={() => setOpen(false)}
                          slides={
                            Array.isArray(image) && image.length > 0
                              ? image.map((src) => ({ src }))
                              : Array.isArray(img) && img.length > 0
                              ? img.map((src) => ({ src }))
                              : []
                          }
                          index={lightboxIndex}
                        />
                      )}
                    </div>
                  </div>
                )}

                {/* product details part end here */}
                {/* related product part start here */}
                <h4 className="mt20">Related Products</h4>
                <hr className="mt0" />
                <div className="swiper productSlider">
                  <Swiper
                    loop={true}
                    slidesPerView={5}
                    spaceBetween={20}
                    keyboard={{
                      enabled: true,
                    }}
                    autoplay={{
                      delay: 3000,
                    }}
                    breakpoints={{
                      320: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                      },
                      640: {
                        slidesPerView: 3,
                        spaceBetween: 15,
                      },
                      768: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                      },
                      1024: {
                        slidesPerView: 5,
                        spaceBetween: 15,
                      },
                    }}
                    navigation={true}
                    modules={[Navigation]}
                    className="mySwiper"
                  >
                    {reletedList.map((item) => (
                      <SwiperSlide key={item._id}>
                        <div className="swiper-slide proBx">
                          <div className="card mb-3">
                            <div className="imgBx">
                              <Link to={`/product/related-product/${item._id}`}>
                                <img
                                  src={item.image}
                                  className="card-img-top"
                                  alt=""
                                  style={{ height: "200px" }}
                                />
                              </Link>
                            </div>
                            <div className="card-body">
                              <h4 className="card-title">
                                <Link>{item.title}</Link>
                              </h4>
                              <p
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  display: "-webkit-box",
                                  WebkitLineClamp: 3,
                                  WebkitBoxOrient: "vertical",
                                }}
                              >
                                {item.description}
                              </p>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
                {/* related product part end here */}
              </div>
            )}
            {/* mid box end */}
            {/* ad box start */}
            <Ads />
            {/* ad box end */}
          </article>
        </article>
      </section>
    </>
  );
};

export default ProductDetail;